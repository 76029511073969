import { makeStyles } from '@material-ui/styles';
import colors from 'assets/styles/colors';

const defaultProps = {
  0: {
    height: '48px',
    fontSize: '16px',
    fontWeight: '700',
    fontColor: colors.secundary_color_700,
    fontColorHover: colors.secundary_color_800,
    fontColorOpen: colors.secundary_color_800,
    padding: '0px 4px 0px 12px',
    background: colors.primary_color_100_48,
    backgroundHover: colors.primary_color_100_56,
    backgroundOpen: colors.primary_color_100_64,
    iconFontSize: '30px',
    borderRadiusSummary: '4px',
    borderColorSummary: `${colors.primary_color_100_48} ${colors.primary_color_100_48} ${colors.primary_color_100} ${colors.primary_color_100_48}`,
    borderWidthSummary: '1px',
    borderRadiusSummaryOpen: '4px 4px 0px 0px',
    borderRadiusRounded: '4px',
    borderRadiusDetails: '0px 0px 4px 4px',
    borderWidthDetails: '0px 1px 1px 1px',
  },
  1: {
    height: '48px',
    fontSize: '14px',
    fontWeight: '500',
    fontColor: colors.secundary_color_700,
    fontColorHover: colors.secundary_color_800,
    fontColorOpen: colors.secundary_color_800,
    padding: '0px 17px 0px 12px',
    background: colors.primary_color_100_36,
    backgroundHover: colors.primary_color_100_48,
    backgroundOpen: colors.primary_color_100_56,
    iconFontSize: '30px',
    borderRadiusSummary: '0px',
    borderWidthSummary: '0px 0px 1px 0px',
    borderColorSummary: colors.primary_color_100,
    borderRadiusSummaryOpen: '0px',
    borderRadiusRounded: '0px',
    borderRadiusDetails: '0px',
    borderWidthDetails: '0px',
  },
  2: {
    height: '48px',
    fontSize: '14px',
    fontWeight: '500',
    fontColor: colors.secundary_color_700,
    fontColorHover: colors.secundary_color_800,
    fontColorOpen: colors.secundary_color_800,
    padding: '0px 30px 0px 12px',
    background: colors.primary_color_100_24,
    backgroundHover: colors.primary_color_100_36,
    backgroundOpen: colors.secundary_color_100_48,
    iconFontSize: '30px',
    borderRadiusSummary: '0px',
    borderWidthSummary: '0px 0px 1px 0px',
    borderColorSummary: colors.secundary_color_100,
    borderRadiusSummaryOpen: '0px',
    borderRadiusRounded: '0px',
    borderRadiusDetails: '0px',
    borderWidthDetails: '0px',
  },
  3: {
    width: '342px',
    height: '30px',
    fontSize: '16px',
    fontWeight: '400',
    fontColor: colors.primary_color_800,
    fontColorHover: colors.primary_color_800,
    fontColorOpen: colors.primary_color_800,
    padding: '4px 4px 4px 8px',
    background: colors.primary_color_100_48,
    backgroundHover: colors.primary_color_100_48,
    backgroundOpen: colors.primary_color_100_24,
    iconFontSize: '24px',
    borderRadiusSummary: '3px',
    borderColorSummary: colors.primary_color_100,
    borderWidthSummary: '0px 0px 1px 0px',
    borderRadiusSummaryOpen: '3px 3px 0px 0px',
    borderRadiusRounded: '3px',
    borderRadiusDetails: '0px',
    borderWidthDetails: '0px',
  },
  4: {
    width: '338px',
    height: '30px',
    fontSize: '16px',
    fontWeight: '400',
    fontColor: colors.primary_color_800,
    fontColorHover: colors.primary_color_800,
    fontColorOpen: colors.primary_color_800,
    padding: '4px 4px 4px 8px',
    background: colors.white_color,
    backgroundHover: colors.white_color,
    backgroundOpen: colors.white_color,
    iconFontSize: '24px',
    borderRadiusSummary: '3px',
    borderColorSummary: colors.primary_color_100,
    borderWidthSummary: '0px 0px 1px 0px',
    borderRadiusSummaryOpen: '3px 3px 0px 0px',
    borderRadiusRounded: '3px',
    borderRadiusDetails: '0px',
    borderWidthDetails: '0px',
  },
  5: {
    height: '40px',
    fontSize: '14px',
    fontWeight: '700',
    fontColor: colors.secundary_color_700,
    fontColorHover: colors.secundary_color_800,
    fontColorOpen: colors.secundary_color_800,
    padding: '0px 24px 0px 12px',
    background: colors.secundary_color_100_36,
    backgroundHover: colors.secundary_color_100_48,
    backgroundOpen: colors.secundary_color_100_56,
    iconFontSize: '30px',
    borderRadiusSummary: '0px',
    borderWidthSummary: '0px 0px 1px 0px',
    borderColorSummary: colors.secundary_color_100,
    borderRadiusSummaryOpen: '0px',
    borderRadiusRounded: '0px',
    borderRadiusDetails: '0px',
    borderWidthDetails: '0px',
  },
  6: {
    height: '40px',
    fontSize: '14px',
    fontWeight: '450',
    fontColor: colors.secundary_color_700,
    fontColorHover: colors.secundary_color_800,
    fontColorOpen: colors.secundary_color_800,
    padding: '0px 32px 0px 12px',
    background: colors.secundary_color_100_16,
    backgroundHover: colors.secundary_color_100_48,
    backgroundOpen: colors.secundary_color_100_16,
    iconFontSize: '30px',
    borderRadiusSummary: '0px',
    borderWidthSummary: '1px 0px 1px 0px',
    borderColorSummary: colors.secundary_color_100_50,
    borderRadiusSummaryOpen: '0px',
    borderRadiusRounded: '0px',
    borderRadiusDetails: '0px',
    borderWidthDetails: '0px',
  },
};

export const useStyles = makeStyles({
  // Icon props
  icon: {
    color: 'inherit',
    fontSize: (props) => defaultProps[props.level].iconFontSize,
  },

  // Accordion props
  accordion: {
    width: (props) => defaultProps[props.level].width,
    boxSizing: 'border-box',
    boxShadow: 'none',
    '&::before': {
      display: 'none',
    },
  },

  rounded: {
    borderRadius: (props) => `${defaultProps[props.level].borderRadiusRounded} !important`,
  },

  expandedAccordion: {
    backgroundColor: (props) => defaultProps[props.level].backgroundOpen,
    margin: '0px !important',
  },

  // Summary props
  summary: {
    height: (props) => defaultProps[props.level].height,
    minHeight: (props) => `${defaultProps[props.level].height} !important`,
    maxHeight: (props) => `${defaultProps[props.level].height} !important`,
    fontSize: (props) => defaultProps[props.level].fontSize,
    color: (props) => defaultProps[props.level].fontColor,
    fontWeight: (props) => defaultProps[props.level].fontWeight,
    lineHeight: '24px',
    backgroundColor: (props) => defaultProps[props.level].background,
    padding: (props) => defaultProps[props.level].padding,
    borderStyle: 'solid',
    borderRadius: (props) => defaultProps[props.level].borderRadiusSummary,
    borderWidth: (props) => defaultProps[props.level].borderWidthSummary,
    borderColor: (props) => `${defaultProps[props.level].borderColorSummary}`,
    '&:hover': {
      backgroundColor: (props) => defaultProps[props.level].backgroundHover,
      color: (props) => defaultProps[props.level].fontColorHover,
      '& .MuiAccordionSummary-expandIcon': {
        color: (props) => defaultProps[props.level].fontColorHover,
      },
    },
  },
  expanded: {
    borderRadius: (props) => `${defaultProps[props.level].borderRadiusSummaryOpen} !important`,
    color: (props) => defaultProps[props.level].fontColorOpen,
  },
  expandIcon: {
    transition: 'none',
    padding: 0,
    marginRight: '0px',
  },
  content: {
    margin: 'unset !important',
    gap: '8px',
    alignItems: 'center',
  },

  // Details props
  details: {
    padding: '0px',
    flexDirection: 'column',
    width: '100%',
    borderStyle: 'solid',
    borderColor: colors.primary_color_100_48,
    borderWidth: (props) => defaultProps[props.level].borderWidthDetails,
    borderRadius: (props) => defaultProps[props.level].borderRadiusDetails,
    backgroundColor: 'white',
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
});

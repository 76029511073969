import {
  React, useState,
} from 'react';
import { Stack, Typography } from '@mui/material';
import CancelScheduleSendRoundedIcon from '@mui/icons-material/CancelScheduleSendRounded';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from 'prop-types';

import SummaryPage from 'features/common/summaryPage';
import DocumentoFormalizarForm from '../documentoFormalizarForm';

const DocumentosFormalizarItem = ({
  item, indexList,
}) => {
  const [openAccordion, setOpenAccordion] = useState(false);
  const [statusEnviado, setStatusEnviado] = useState(-1);

  const renderItemStatusDealer = () => {
    const status = (statusEnviado === 0) ? 'Enviado' : 'Envio Pendente';
    const color = (statusEnviado === 0) ? '#06C270' : '#BF8900';

    return (
      (statusEnviado >= 0)
      && (
        <Stack columnGap="4px" direction="row" color={color}>
          {(statusEnviado === 0) && (
            <CheckIcon sx={{ fontSize: '16px' }} />
          )}
          {(statusEnviado > 0) && (
            <CancelScheduleSendRoundedIcon sx={{ fontSize: '17px' }} />
          )}
          <Typography variant="10_medium" color={color} lineHeight="16px" fontSize="12px">{status}</Typography>
        </Stack>
      )
    );
  };

  return (
    <SummaryPage
      level={5}
      title={item?.nome}
      open={openAccordion}
      handleOpenChange={setOpenAccordion}
      IconTitle={renderItemStatusDealer()}
    >
      <DocumentoFormalizarForm
        documento={item}
        indexList={indexList}
        setStatus={setStatusEnviado}
        editMode
      />
    </SummaryPage>
  );
};

DocumentosFormalizarItem.propTypes = {
  item: PropTypes.object,
  indexList: PropTypes.number.isRequired,
};

DocumentosFormalizarItem.defaultProps = {
  item: {},
};

export default DocumentosFormalizarItem;

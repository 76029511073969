/* eslint-disable class-methods-use-this */
import axios from 'axios';
import { formatDateForUrl } from 'utils/axios';

class ComunicadosService {
  async getFilters() {
    const result = await axios.get(`${window.env.REACT_APP_API_URL}/central-comunicados/filter/brands-hub`);
    if (!result.data) throw new Error('Erro ao buscar as brands de comunicados');

    if (result.status === 200) {
      const brandsList = result.data.map((item) => {
        const value = item.descricao.toLowerCase();
        const text = item.descricao.toUpperCase();
        return { value, text };
      });
      return brandsList;
    }
    return [];
  }

  async getDves(filtros, page, isFundao) {
    const payload = {
      brands: filtros.brand.map((t) => t.value),
      query: filtros.documento || '',
      page: page.page,
      itensPorPage: page.ipp,
      isFundao,
      dataInicio: filtros.startDate ? formatDateForUrl(filtros.startDate) : null,
      dataFim: filtros.endDate ? formatDateForUrl(filtros.endDate) : null,
    };

    const response = await axios.post(`${window.env.REACT_APP_API_URL}/central-comunicados/documentos-hub`, payload);

    if (response.status !== 200 || !response.data) {
      throw new Error('Erro ao buscar comunicados');
    }
    response.data.documentos = response.data.documentos.map((d) => ({
      ...d,
      dataEmissao: new Date(d.dataEmissao),
    }));

    return response.data;
  }
}
export default new ComunicadosService();

import colors from 'assets/styles/colors';

export default {
  primary500: {
    main: colors.primary_color_500,
    light: colors.primary_color_400,
    dark: colors.primary_color_600,
    active: colors.primary_color_800,
    activeOutlined: colors.primary_color_100,
    contrastText: 'white',
  },
  primary700: {
    main: colors.primary_color_700,
    light: colors.primary_color_600,
    dark: colors.primary_color_800,
    active: colors.primary_color_900,
    contrastText: 'white',
  },
  secondary700: {
    main: colors.secundary_color_700,
    light: colors.secundary_color_600,
    dark: colors.secundary_color_800,
    active: colors.secundary_color_900,
    activeOutlined: colors.secundary_color_600_32,
    contrastText: 'white',
  },
  icon: {
    main: colors.icon_color,
    light: colors.icon_color,
    dark: colors.icon_color,
  },
  alert200: {
    main: colors.alert_color_200,
    light: colors.alert_color_100,
    dark: colors.alert_color_300,
    active: colors.alert_color_500,
    contrastText: 'white',
  },
  error300: {
    main: colors.error_color_300,
    light: colors.error_color_200,
    dark: colors.error_color_400,
    active: colors.alert_color_500,
    contrastText: 'white',
  },
  warning4: {
    main: colors.warning_color_4,
    light: colors.warning_color_4,
    dark: colors.warning_color_4,
    active: colors.warning_color_4,
    contrastText: 'white',
  },
};

// Ações de seleção, carregar os filtros do back

const PREFIX = 'comunicados';

const SET_FILTER = `${PREFIX}/SET_FILTER`;
const SET_BRAND_LIST = `${PREFIX}/SET_BRAND_LIST`;
const RESET_STORE = `${PREFIX}/RESET_STORE`;

const GET_DVES_START = `${PREFIX}/GET_DVES_START`;
const GET_DVES_ERROR = `${PREFIX}/GET_DVES_ERROR`;
const GET_DVES_SUCCESS = `${PREFIX}/GET_DVES_SUCCESS`;

const SET_PAGINATION_CONTROL = `${PREFIX}/SET_PAGINATION_CONTROL`;
const DISMISS_SNACKBAR = `${PREFIX}/DISMISS_SNACKBAR`;

const setFilter = (paramName, value) => ({
  type: SET_FILTER,
  payload: { paramName, value },
});

const setBrandList = (brandList) => ({
  type: SET_BRAND_LIST,
  payload: { brandList },
});

const resetStore = () => ({
  type: RESET_STORE,
});

const getDvesStart = () => ({
  type: GET_DVES_START,
});

const getDvesError = () => ({
  type: GET_DVES_ERROR,
});

const getDvesSuccess = (documentos, pageControl) => ({
  type: GET_DVES_SUCCESS,
  payload: { documentos, pageControl },
});

const dismissSnack = (id) => ({
  type: DISMISS_SNACKBAR,
  payload: { id },
});

const setPageParams = (paramName, value) => ({
  type: SET_PAGINATION_CONTROL,
  payload: { paramName, value },
});

export default {
  types: {
    SET_FILTER,
    SET_BRAND_LIST,
    GET_DVES_ERROR,
    GET_DVES_START,
    GET_DVES_SUCCESS,
    RESET_STORE,
    SET_PAGINATION_CONTROL,
    DISMISS_SNACKBAR,
  },
  setFilter,
  setBrandList,
  resetStore,
  getDvesError,
  setPageParams,
  getDvesStart,
  getDvesSuccess,
  dismissSnack,
};

import { connect } from 'react-redux';

import operations from '../../../redux/operations';

import BrandsSelect from './brandsSelect';

const mapStateToProps = ({ comunicados }) => ({
  brand: comunicados.page.filtros.brand,
  brandList: comunicados?.page?.filtros?.brandList,
  isLoading: comunicados?.page?.requestStatus?.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setBrand: (value) => dispatch(operations.setFilter('brand', value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandsSelect);

import { connect } from 'react-redux';
import { SnackbarOperations } from 'modules/snackbar';
import DocumentoFormalizarForm from './documentoFormalizarForm';
import operations from '../../redux/operations';
import { isEdicaoDocDesabilitadaParaDealer } from '../../../limitesAprovadosPage/redux/selector';

const mapStateToProps = ({ limitesAprovados }) => ({
  disableDealerDocEdition: isEdicaoDocDesabilitadaParaDealer(limitesAprovados),
});

const mapDispatchToProps = (dispatch) => ({
  uploadAnexo: async (file, idDocumentoFormalizarJuridico, indexList) => dispatch(
    operations.uploadAnexoDocumentoFormalizar(file, idDocumentoFormalizarJuridico, indexList),
  ),
  downloadAnexo: async (idDocumento) => dispatch(
    operations.downloadAnexoDocumentoFormalizar(idDocumento),
  ),
  deleteAnexo: (nomeGuid, indexList) => dispatch(
    operations.deleteAnexoDocumentoFormalizar(nomeGuid, indexList),
  ),
  handleInvalidAnexo: (message) => dispatch(SnackbarOperations.addSnackbar(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentoFormalizarForm);

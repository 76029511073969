import React from 'react';
import PropTypes from 'prop-types';
import { camelFormat } from 'utils/format';

import NewBasicSelect from 'features/common/newBasicSelect/newBasicSelect';

const defaultValue = [{
  value: new Date().getMonth() + 1,
  label: camelFormat(new Date().toLocaleString('pt-BR', { month: 'long' })),
}];
const MesSelector = ({
  mes, setMes, mesesList, isLoading,
}) => (
  <NewBasicSelect
    selectFirstOptionDefault
    renderAllOptions={false}
    dataCy="taxaMes"
    nameLabel="Mes"
    options={mesesList.length > 0 ? mesesList : defaultValue}
    selectedOption={mes}
    setOption={(item) => {
      setMes(item);
    }}
    isLoading={isLoading}
  />
);

MesSelector.propTypes = {
  mes: PropTypes.number,
  setMes: PropTypes.func,
  mesesList: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
};

MesSelector.defaultProps = {
  mes: new Date().getMonth() + 1,
  setMes: () => {},
  mesesList: null,
  isLoading: false,
};

export default MesSelector;

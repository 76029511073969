import logger from 'utils/logger';
import actions from './actions';
import service from './service';

const setFilter = (paramName, value) => (dispatch) => {
  dispatch(actions.setFilter(paramName, value));
};

const resetStore = () => (dispatch) => {
  dispatch(actions.resetStore());
};

const getFilters = () => async (dispatch) => {
  try {
    const response = await service.getFilters();
    dispatch(actions.setBrandList(response));
  } catch (e) {
    logger.error(e);
  }
};

const getDve = () => async (dispatch, getState) => {
  try {
    dispatch(actions.getDvesStart());
    const { filtros, paginationControl } = getState().comunicados.page;
    const isFundao = getState().dealerStore.dealers.find((d) => d.isFundao === 1) ? 1 : 0;
    const response = await service.getDves(filtros, paginationControl, isFundao);
    const page = {
      ipp: response.itensPorPage,
      page: response.page,
      totalItems: response.totalItens,
    };
    dispatch(actions.getDvesSuccess(response.documentos, page));
  } catch (error) {
    logger.error(error);
    dispatch(actions.getDvesError());
  }
};

const dismissSnack = (id) => (dispatch) => {
  dispatch(actions.dismissSnack(id));
};

const setPageParams = (propertyName, value) => async (dispatch) => {
  dispatch(actions.setPageParams(propertyName, value));
  dispatch(getDve());
};

export default {
  setFilter,
  resetStore,
  getFilters,
  getDve,
  setPageParams,
  dismissSnack,
};

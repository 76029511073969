import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mui/material/Icon';
import AlertCardStyle from './alertCard.style';

const AlertCard = ({
  height,
  padding,
  margin,
  lineHeight,
  title,
  subtitle,
  icone,
  colorBase,
  width,
  className,
  afterTitle,
  alertCardContent,
  staff,
  docInfo,
  borderColor,
}) => {
  function Icone() {
    return (
      <Icon>{icone}</Icon>
    );
  }
  return (
    <AlertCardStyle
      colorBase={colorBase}
      width={width}
      className={className}
      height={height}
      padding={padding}
      margin={margin}
      lineHeight={lineHeight}
      alertCardContent={alertCardContent}
      staff={staff}
      borderColor={borderColor}
      docInfo={docInfo}
    >
      <div className="alert-card-content">
        <div className="alert-card-title" data-cy="alert-title">
          <Icone />
          <span>
            {title}
          </span>
          {afterTitle}
        </div>
        <div className="alert-card-subtitle">
          {subtitle}
        </div>
      </div>
    </AlertCardStyle>
  );
};

AlertCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icone: PropTypes.element,
  colorBase: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  lineHeight: PropTypes.string,
  className: PropTypes.string,
  afterTitle: PropTypes.node,
  alertCardContent: PropTypes.string,
  staff: PropTypes.bool,
  docInfo: PropTypes.bool,
  borderColor: PropTypes.string,
};

AlertCard.defaultProps = {
  title: '',
  subtitle: '',
  icone: null,
  colorBase: '',
  height: null,
  padding: null,
  margin: null,
  lineHeight: null,
  width: '342px',
  className: '',
  afterTitle: null,
  alertCardContent: '16px',
  staff: false,
  docInfo: false,
  borderColor: '',
};
export default AlertCard;

import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';
import { camelFormat, capitalize, formatDate } from 'utils/format';

const isFundao = createSelector(
  (state) => state.dealers,
  (dealers) => (!!dealers.find((d) => d.isFundao === 1)),
);

const isBrand = createSelector(
  (state) => state.dealers,
  (dealers) => {
    const brands = ['PEUGEOT', 'CITROEN', 'FIAT', 'JEEP', 'CHRYSLER', 'DODGE', 'RAM'];
    if (!dealers) return [];
    const uniqueBrands = Array.from(
      new Set(
        dealers
          .map((d) => d.brand)
          .filter((brand) => brands.includes(brand)),
      ),
    ).sort();
    return uniqueBrands;
  },
);

const getListMeses = createSelector(
  (state) => state.carteira.modalTaxas.selectors.mes,
  (meses) => (meses ?? []).map((m) => {
    const date = moment().month(m - 1);
    return {
      value: m,
      label: camelFormat(formatDate(date, 'MMMM')),
    };
  }),
);

const getListAnos = createSelector(
  (state) => state.carteira.modalTaxas.selectors.ano,
  (anos) => (anos ?? []).map((a) => ({
    value: a,
    label: `${a}`,
  })),
);

const getListBrands = createSelector(
  isBrand,
  (brands) => brands.map((brand) => ({
    label: capitalize(brand?.toLowerCase()),
    value: capitalize(brand?.toLowerCase()),
  })),
);

const createTaxaObject = (
  header,
  firstRowName,
  secondRowName,
  firstRowValue,
  secondRowValue,
  firstRowPraticada,
  secondRowPraticada,
  showFirstRow,
  showSecondRow,
) => ({
  header,
  firstRowName,
  secondRowName,
  firstRowValue,
  secondRowValue,
  firstRowPraticada,
  secondRowPraticada,
  showFirstRow,
  showSecondRow,
});

const mapTaxaObjects = (t) => ([
  createTaxaObject(
    'NOVOS',
    'FLOOR PLAN',
    'FUNDO',
    t.novoFloorPlan,
    t.novoFundao,
    t.novoFloorPlanPraticada,
    t.novoFundaoPraticada,
    true,
    true,
  ),

  createTaxaObject(
    'USADOS',
    'FLOOR PLAN',
    'FUNDO',
    t.usadosFloorPlan,
    t.usadosFundao,
    t.usadosFloorPlanPraticada,
    t.usadosFundaoPraticada,
    true,
    true,
  ),

  createTaxaObject(
    'PEÇAS',
    null,
    null,
    t.pecas,
    null,
    t.pecasPraticada,
    null,
    true,
    false,
  ),

  createTaxaObject(
    'IDENTIDADE VISUAL',
    null,
    null,
    t.identidadeVisual,
    null,
    t.identidadeVisualPraticada,
    null,
    true,
    false,
  ),

  createTaxaObject(
    'TEST DRIVE',
    null,
    null,
    t.testDrive,
    null,
    t.testDrivePraticada,
    null,
    true,
    false,
  ),
]);

const getTaxas = createSelector(
  (state) => state.carteira.modalTaxas.taxas,
  (taxas) => {
    if (_.isEmpty(taxas) || _.isUndefined(taxas) || _.isNull(taxas)) { return []; }
    return taxas.map((t) => {
      const { inicioVigencia, fimVigencia, ...taxa } = t;
      const { brand } = t;
      return {
        brand,
        inicioVigencia,
        fimVigencia,
        taxas: mapTaxaObjects(taxa),
      };
    });
  },
);

export default {
  isFundao,
  isBrand,
  getListMeses,
  getListAnos,
  getListBrands,
  getTaxas,
};

import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import SummaryPage from 'features/common/summaryPage';
import Loading from 'features/common-staff/layout/loading';
import InfoCadastroList from './infoCadastroList';

const DocumentosCadastro = ({
  listaPessoaDocumentacao, gerenciadorDocumentos, isLoading, statusLimite,
}) => (
  isLoading
    ? (
      <div style={{ padding: '24px' }}>
        <Loading />
      </div>
    )
    : !_.isEmpty(listaPessoaDocumentacao) && (
      <SummaryPage title="Documentos do cadastro">
        {listaPessoaDocumentacao?.map((item, index) => (
          <InfoCadastroList
            key={index}
            listaPessoaDocumentacao={item}
            gerenciadorDocumentos={gerenciadorDocumentos.filter(
              (doc) => doc.idPessoaDocumento === item.pessoaDocumentacao.idPessoaDocumentacao,
            )}
            statusLimite={statusLimite}
          />
        ))}
      </SummaryPage>
    ));
DocumentosCadastro.propTypes = {
  listaPessoaDocumentacao: PropTypes.array,
  gerenciadorDocumentos: PropTypes.array,
  isLoading: PropTypes.bool,
  statusLimite: PropTypes.string,
};

DocumentosCadastro.defaultProps = {
  gerenciadorDocumentos: [],
  listaPessoaDocumentacao: [],
  isLoading: false,
  statusLimite: '',
};

export default DocumentosCadastro;

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import NewBasicSelect from 'features/common/newBasicSelect/newBasicSelect';

const selectInitialBrand = (setBrand, brand, brandList) => {
  if (!_.isNull(brand)) return brand;

  if (brandList.length >= 1) {
    setBrand(brandList[0].value, false);
    return brandList[0].value;
  }

  return null;
};

const BrandSelector = ({
  setBrand, brand, brandList, isLoading,
}) => (
  <NewBasicSelect
    selectFirstOptionDefault
    renderAllOptions={false}
    dataCy="taxaBrands"
    nameLabel="Brands"
    placeholder="Selecionar brand"
    options={brandList}
    selectedOption={selectInitialBrand(setBrand, brand, brandList)}
    setOption={(item) => {
      setBrand(item);
    }}
    isLoading={isLoading}
  />
);

BrandSelector.propTypes = {
  setBrand: PropTypes.func,
  brand: PropTypes.string,
  brandList: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
};

BrandSelector.defaultProps = {
  setBrand: () => {},
  brand: null,
  brandList: null,
  isLoading: false,
};

export default BrandSelector;

export default {
  gradientLogin: 'linear-gradient(180deg, #243782 32.25%, #2F3567 81.61%)',
  gradientMenu: 'linear-gradient(180deg, #243782 0%, #304AAF 100%)',
  primary_color_900: '#090E20',
  primary_color_800: '#121C41',
  primary_color_700: '#1B2961',
  primary_color_600: '#243782',
  primary_color_500: '#304AAF',
  primary_color_400: '#4B66CD',
  primary_color_300: '#788CDA',
  primary_color_200: '#A5B2E6',
  primary_color_100: '#D2D9F3',
  primary_color_100_64: 'rgba(210, 217, 243, 0.64)',
  primary_color_100_56: 'rgba(210, 217, 243, 0.56)',
  primary_color_100_50: 'rgba(210, 217, 243, 0.50)',
  primary_color_100_48: 'rgba(210, 217, 243, 0.48)',
  primary_color_100_36: 'rgba(210, 217, 243, 0.36)',
  primary_color_100_32: 'rgba(210, 217, 243, 0.32)',
  primary_color_100_24: 'rgba(210, 217, 243, 0.24)',
  primary_color_100_16: 'rgba(210, 217, 243, 0.16)',
  primary_color_100_08: 'rgba(210, 217, 243, 0.08)',
  secundary_color_900: '#282B34',
  secundary_color_800: '#3C414E',
  secundary_color_700: '#505669',
  secundary_color_600: '#656C83',
  secundary_color_600_16: 'rgba(101, 108, 131, 0.16)',
  secundary_color_600_32: 'rgba(101, 108, 131, 0.32)',
  secundary_color_500: '#7C839A',
  secundary_color_400: '#969CAE',
  secundary_color_300: '#B0B5C2',
  secundary_color_200: '#CACED7',
  secundary_color_100: '#E5E6EB',
  secundary_color_100_56: 'rgba(229, 230, 235, 0.56)',
  secundary_color_100_50: 'rgba(229, 230, 235, 0.50)',
  secundary_color_100_48: 'rgba(229, 230, 235, 0.48)',
  secundary_color_100_36: 'rgba(229, 230, 235, 0.36)',
  secundary_color_100_24: 'rgba(229, 230, 235, 0.24)',
  secundary_color_100_16: 'rgba(229, 230, 235, 0.16)',
  secundary_color_100_01: 'rgba(229, 230, 235, 0.01)',
  terciary_color_900: '#081514',
  terciary_color_800: '#112B28',
  terciary_color_700: '#19403C',
  terciary_color_600: '#225550',
  terciary_color_500: '#2A6A64',
  terciary_color_400: '#327F78',
  terciary_color_300: '#3B958C',
  terciary_color_200: '#43AAA0',
  terciary_color_100: '#9DD8D3',
  terciary_color_100_24: 'rgba(157, 216, 211, 0.24)',
  alert_color_500: '#783F00',
  alert_color_400: '#9F5300',
  alert_color_300: '#C76800',
  alert_color_200: '#EF7D00',
  alert_color_200_80: 'rgba(239, 125, 0, 0.8)',
  alert_color_200_08: 'rgba(239, 125, 0, 0.08)',
  alert_color_100: '#FFBE78',
  alert_color_100_48: 'rgba(255, 190, 120, 0.48)',
  alert_color_100_36: 'rgba(255, 190, 120, 0.36)',
  alert_color_100_24: 'rgba(255, 190, 120, 0.24)',
  alert_color_100_16: 'rgba(255, 190, 120, 0.16)',
  alert_color_100_08: 'rgba(255, 190, 120, 0.08)',
  success_color_500: '#0B2117',
  success_color_400: '#15432F',
  success_color_300: '#206446',
  success_color_200: '#70D1A6',
  success_color_100: '#B7E8D3',
  success_color_100_48: 'rgba(183, 232, 211, 0.48)',
  success_color_100_24: 'rgba(183, 232, 211, 0.24)',
  error_color_600_1: 'rgba(228, 35, 19, 1)',
  error_color_500: '#82140B',
  error_color_400: '#A3190E',
  error_color_300: '#C31E10',
  error_color_300_08: 'rgba(195, 30, 16, 0.08)',
  error_color_200: '#E42313',
  error_color_100: '#F58E86',
  error_color_100_46: 'rgba(245, 142, 134, 0.46)',
  error_color_100_36: 'rgba(245, 142, 134, 0.36)',
  error_color_100_56: 'rgba(245, 142, 134, 0.56)',
  error_color_100_24: 'rgba(245, 142, 134, 0.24)',
  error_color_100_16: 'rgba(245, 142, 134, 0.16)',
  error_color_100_08: 'rgba(245, 142, 134, 0.08)',
  process_color_500: '#050D2F',
  process_color_400: '#081447',
  process_color_300: '#0F288E',
  process_color_200: '#1436BD',
  process_color_100: '#778FF1',

  box_shadow_color: '0 4px 8px 0 rgba(85, 87, 112, 0.16), 0 0 2px 0 rgba(85, 87, 112, 0.08)',
  white_color: 'rgba(255, 255, 255)',
  silver_100: '#EDF1F7',
  silver_100_24: 'rgba(228, 233, 242, 0.24)',
  cyan_100: '#F7F9FC',
  accent_steel_200: '#C5CEE0',
  text_midnight_900: '#404154',
  text_midnight_500: '#555770',
  text_midnight_200_08: 'rgba(85, 87, 112, 0.08)',
  teal_mint_500: '#00AFAD',
  teal_mint_500_16: 'rgba(0, 175, 173, 0.16)',
  teal_mint_500_08: 'rgba(0, 175, 173, 0.08)',
};

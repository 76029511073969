import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import colors from 'assets/styles/colors';

export const StyledButton = styled(Button)(({ error }) => ({
  '&:hover, &:active, &:focus': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  '& .MuiButton-startIcon': {
    marginRight: '4px',
  },
  backgroundColor: 'transparent',
  color: error ? colors.error_color_200 : colors.primary_color_400,
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 350,
  textTransform: 'none',
  boxShadow: 'none',
  border: '1px solid #304AAF',
  height: '40px',
  display: 'flex',
  padding: '8px 12px',
  width: '140px',
}));

export const StyledAttachFileIcon = styled(AttachFileIcon)(({ error }) => ({
  width: 18,
  height: 18,
  color: error ? colors.error_color_200 : colors.primary_color_400,
}));

import { applyProperty } from 'utils/object';
import actions from './actions';

const INITIAL_STATE = {
  documentos: [],
  selector: {
    documento: [],
  },

  filtros: {
    documento: null,
    brand: null,
    brandList: [],
    startDate: null,
    endDate: null,
  },

  isFilterSelected: false,

  requestStatus: {
    isLoading: false,
    isError: false,
  },

  snackbarErrors: [],
  snackbarId: 0,

  paginationControl: {
    page: 0,
    ipp: 25,
    totalItems: 0,
  },
};

export default (state = INITIAL_STATE, action = { type: '@@comunicados/INIT' }) => {
  switch (action.type) {
    case actions.types.SET_FILTER:
      return {
        ...state,
        filtros: {
          ...applyProperty(
            state.filtros,
            action.payload.paramName,
            action.payload.value,
          ),
        },
        isFilterSelected: true,
      };
    case actions.types.GET_DVES_START:
      return {
        ...state,
        requestStatus: {
          isLoading: true,
          isError: false,
        },
      };
    case actions.types.GET_DVES_ERROR:
      return {
        ...state,
        requestStatus: {
          isLoading: false,
          isError: true,
        },
        paginationControl: {
          ...state.paginationControl,
          page: 0,
          totalItems: 0,
        },
      };
    case actions.types.GET_DVES_SUCCESS:
      return {
        ...state,
        requestStatus: {
          isLoading: false,
          isError: false,
        },
        documentos: action.payload.documentos,
        paginationControl: action.payload.pageControl,
        isFilterSelected: false,
      };
    case actions.types.DISMISS_SNACKBAR:
      return {
        ...state,
        snackbarErrors: state.snackbarErrors.filter((item) => item.id !== action.payload.id),
      };
    case actions.types.SET_PAGINATION_CONTROL:
      return {
        ...state,
        paginationControl: {
          ...applyProperty(
            state.paginationControl,
            action.payload.paramName,
            action.payload.value,
          ),
        },
      };
    case actions.types.RESET_STORE:
      return INITIAL_STATE;
    case actions.types.SET_BRAND_LIST:
      return {
        ...state,
        filtros: {
          ...state.filtros,
          brandList: action.payload.brandList,
        },
      };
    default:
      return state;
  }
};

import { createSelector } from 'reselect';

const getQuantidadeBrands = createSelector(
  (state) => state.dealers,
  (dealers) => {
    const brands = [...new Set(dealers.map((d) => d.brand))];
    return brands.length;
  },
);

export default {
  getQuantidadeBrands,
};

const PREFIX_LIMITES_APROVADOS_UPDATE = 'limitesAprovadosCadastro/UPDATE_LIMITE';

const UPDATE_LIMITE_START = `${PREFIX_LIMITES_APROVADOS_UPDATE}/START`;
const UPDATE_LIMITE_ERROR = `${PREFIX_LIMITES_APROVADOS_UPDATE}/ERROR`;
const UPDATE_LIMITE_SUCCESS = `${PREFIX_LIMITES_APROVADOS_UPDATE}/SUCCESS`;

const SET_SELECTED_ITEMS = 'limitesAprovadosCadastro/SET_SELECTED_ITEMS';
const INSERT_TIPO_RELACIONAMENTO_START = 'limitesAprovadosCadastro/INSERT_TIPO_RELACIONAMENTO_START';
const INSERT_TIPO_RELACIONAMENTO_SUCCESS = 'limitesAprovadosCadastro/INSERT_TIPO_RELACIONAMENTO_SUCCESS';
const INSERT_TIPO_RELACIONAMENTO_ERROR = 'limitesAprovadosCadastro/INSERT_TIPO_RELACIONAMENTO_ERROR';

const INSERT_TIPO_DOCUMENTO_START = 'limitesAprovadosCadastro/INSERT_TIPO_DOCUMENTO_START';
const INSERT_TIPO_DOCUMENTO_SUCCESS = 'limitesAprovadosCadastro/INSERT_TIPO_DOCUMENTO_SUCCESS';
const INSERT_TIPO_DOCUMENTO_ERROR = 'limitesAprovadosCadastro/INSERT_TIPO_DOCUMENTO_ERROR';

const INSERT_PESSOA_DOCUMENTACAO_START = 'limitesAprovadosCadastro/INSERT_PESSOA_DOCUMENTACAO_START';
const INSERT_PESSOA_DOCUMENTACAO_SUCCESS = 'limitesAprovadosCadastro/INSERT_PESSOA_DOCUMENTACAO_SUCCESS';
const INSERT_PESSOA_DOCUMENTACAO_ERROR = 'limitesAprovadosCadastro/INSERT_PESSOA_DOCUMENTACAO_ERROR';

const GET_PESSOA_DOCUMENTACAO_START = 'limitesAprovadosCadastro/GET_PESSOA_DOCUMENTACAO_START';
const GET_PESSOA_DOCUMENTACAO_SUCCESS = 'limitesAprovadosCadastro/GET_PESSOA_DOCUMENTACAO_SUCCESS';
const GET_PESSOA_DOCUMENTACAO_ERROR = 'limitesAprovadosCadastro/GET_PESSOA_DOCUMENTACAO_ERROR';

const DELETE_PESSOA_DOCUMENTACAO_START = 'limitesAprovadosCadastro/DELETE_PESSOA_DOCUMENTACAO_START';
const DELETE_PESSOA_DOCUMENTACAO_SUCCESS = 'limitesAprovadosCadastro/DELETE_PESSOA_DOCUMENTACAO_SUCCESS';
const DELETE_PESSOA_DOCUMENTACAO_ERROR = 'limitesAprovadosCadastro/DELETE_PESSOA_DOCUMENTACAO_ERROR';

const BUILD_GERENCIADOR = 'limitesAprovadosCadastro/BUILD_GERENCIADOR';

const DOWNLOAD_DOCUMENTO_START = 'limitesAprovadosCadastro/DOWNLOAD_DOCUMENTO_START';
const DOWNLOAD_DOCUMENTO_SUCCESS = 'limitesAprovadosCadastro/DOWNLOAD_DOCUMENTO_SUCCESS';
const DOWNLOAD_DOCUMENTO_ERROR = 'limitesAprovadosCadastro/DOWNLOAD_DOCUMENTO_ERROR';

const UPDATE_DOCUMENTO_START = 'limitesAprovadosCadastro/UPDATE_DOCUMENTO_START';
const UPDATE_DOCUMENTO_SUCCESS = 'limitesAprovadosCadastro/UPDATE_DOCUMENTO_SUCCESS';
const UPDATE_DOCUMENTO_ERROR = 'limitesAprovadosCadastro/UPDATE_DOCUMENTO_ERROR';

const DELETE_DOCUMENTO_START = 'limitesAprovadosCadastro/DELETE_DOCUMENTO_START';
const DELETE_DOCUMENTO_SUCCESS = 'limitesAprovadosCadastro/DELETE_DOCUMENTO_SUCCESS';
const DELETE_DOCUMENTO_ERROR = 'limitesAprovadosCadastro/DELETE_DOCUMENTO_ERROR';

const SET_DOCUMENTO_ERROR = 'limitesAprovadosCadastro/SET_DOCUMENTO_ERROR';

const GET_DOCUMENTOS_COMPLEMENTARES_START = 'limitesAprovadosCadastro/GET_DOCUMENTOS_COMPLEMENTARES_START';
const GET_DOCUMENTOS_COMPLEMENTARES_SUCCESS = 'limitesAprovadosCadastro/GET_DOCUMENTOS_COMPLEMENTARES_SUCCESS';
const GET_DOCUMENTOS_COMPLEMENTARES_ERROR = 'limitesAprovadosCadastro/GET_DOCUMENTOS_COMPLEMENTARES_ERROR';

const INSERT_DOCUMENTO_COMPLEMENTAR = 'limitesAprovadosCadastro/INSERT_DOCUMENTO_COMPLEMENTAR';
const DELETE_DOCUMENTO_COMPLEMENTAR = 'limitesAprovadosCadastro/DELETE_DOCUMENTO_COMPLEMENTAR';

const GET_DOCUMENTOS_FORMALIZAR_START = 'limitesAprovadosCadastro/GET_DOCUMENTOS_FORMALIZAR_START';
const GET_DOCUMENTOS_FORMALIZAR_SUCCESS = 'limitesAprovadosCadastro/GET_DOCUMENTOS_FORMALIZAR_SUCCESS';
const GET_DOCUMENTOS_FORMALIZAR_ERROR = 'limitesAprovadosCadastro/GET_DOCUMENTOS_FORMALIZAR_ERROR';

const UPDATE_DOCUMENTO_FORMALIZAR_START = 'limitesAprovadosCadastro/UPDATE_DOCUMENTO_FORMALIZAR_START';
const UPDATE_DOCUMENTO_FORMALIZAR_SUCCESS = 'limitesAprovadosCadastro/UPDATE_DOCUMENTO_FORMALIZAR_SUCCESS';
const UPDATE_DOCUMENTO_FORMALIZAR_ERROR = 'limitesAprovadosCadastro/UPDATE_DOCUMENTO_FORMALIZAR_ERROR';

const INSERT_DOCUMENTO_FORMALIZAR = 'limitesAprovadosCadastro/INSERT_DOCUMENTO_FORMALIZAR';
const DELETE_DOCUMENTO_FORMALIZAR = 'limitesAprovadosCadastro/DELETE_DOCUMENTO_FORMALIZAR';

const RESET_STORE = 'limitesAprovadosCadastro/RESET_STORE';

const resetStore = () => ({ type: RESET_STORE });

const updateLimiteStart = () => ({
  type: UPDATE_LIMITE_START,
});

const updateLimiteSuccess = (filters) => ({
  type: UPDATE_LIMITE_SUCCESS,
  payload: { filters },
});

const updateLimiteError = () => ({
  type: UPDATE_LIMITE_ERROR,
});

const setSelectedItems = (idTipo) => ({
  type: SET_SELECTED_ITEMS,
  payload: { idTipo },
});

const insertTipoRelacionamentoStart = () => ({
  type: INSERT_TIPO_RELACIONAMENTO_START,
});

const insertTipoRelacionamentoSuccess = (relacionamentoList) => ({
  type: INSERT_TIPO_RELACIONAMENTO_SUCCESS,
  payload: { relacionamentoList },
});

const insertTipoRelacionamentoError = () => ({
  type: INSERT_TIPO_RELACIONAMENTO_ERROR,
});

const insertTipoDocumentoStart = () => ({
  type: INSERT_TIPO_DOCUMENTO_START,
});

const insertTipoDocumentoSuccess = (documentoList) => ({
  type: INSERT_TIPO_DOCUMENTO_SUCCESS,
  payload: { documentoList },
});

const insertTipoDocumentoError = () => ({
  type: INSERT_TIPO_DOCUMENTO_ERROR,
});

const insertPessoaDocumentacaoStart = () => ({
  type: INSERT_PESSOA_DOCUMENTACAO_START,
});

const insertPessoaDocumentacaoSuccess = () => ({
  type: INSERT_PESSOA_DOCUMENTACAO_SUCCESS,
});

const insertPessoaDocumentacaoError = () => ({
  type: INSERT_PESSOA_DOCUMENTACAO_ERROR,
});

const getPessoaDocumentacaoStart = () => ({
  type: GET_PESSOA_DOCUMENTACAO_START,
});

const getPessoaDocumentacaoSuccess = (
  listaPessoaDocumentacao,
) => ({
  type: GET_PESSOA_DOCUMENTACAO_SUCCESS,
  payload: {
    listaPessoaDocumentacao,
  },
});

const getPessoaDocumentacaoError = () => ({
  type: GET_PESSOA_DOCUMENTACAO_ERROR,
});

const deletePessoaDocumentacaoStart = () => ({
  type: DELETE_PESSOA_DOCUMENTACAO_START,
});

const deletePessoaDocumentacaoSuccess = () => ({
  type: DELETE_PESSOA_DOCUMENTACAO_SUCCESS,
});

const deletePessoaDocumentacaoError = () => ({
  type: DELETE_PESSOA_DOCUMENTACAO_ERROR,
});

const buildGerenciadorDocumentos = (gerenciadorDocumentos) => ({
  type: BUILD_GERENCIADOR,
  payload: { gerenciadorDocumentos },
});

const downloadDocumentoStart = (gerenciadorDocumentos) => ({
  type: DOWNLOAD_DOCUMENTO_START,
  payload: { gerenciadorDocumentos },
});

const downloadDocumentoSuccess = (gerenciadorDocumentos, urlDownload) => ({
  type: DOWNLOAD_DOCUMENTO_SUCCESS,
  payload: { gerenciadorDocumentos, urlDownload },
});

const downloadDocumentoError = (gerenciadorDocumentos, urlDownload) => ({
  type: DOWNLOAD_DOCUMENTO_SUCCESS,
  payload: { gerenciadorDocumentos, urlDownload },
});

const updateDocumentoStart = (gerenciadorDocumentos) => ({
  type: UPDATE_DOCUMENTO_START,
  payload: { gerenciadorDocumentos },
});

const updateDocumentoSuccess = (gerenciadorDocumentos) => ({
  type: UPDATE_DOCUMENTO_SUCCESS,
  payload: { gerenciadorDocumentos },
});

const updateDocumentoError = (gerenciadorDocumentos) => ({
  type: UPDATE_DOCUMENTO_ERROR,
  payload: { gerenciadorDocumentos },
});

const deleteDocumentoStart = (gerenciadorDocumentos) => ({
  type: DELETE_DOCUMENTO_START,
  payload: { gerenciadorDocumentos },
});

const deleteDocumentoSuccess = (gerenciadorDocumentos) => ({
  type: DELETE_DOCUMENTO_SUCCESS,
  payload: { gerenciadorDocumentos },
});

const deleteDocumentoError = (gerenciadorDocumentos) => ({
  type: DELETE_DOCUMENTO_ERROR,
  payload: { gerenciadorDocumentos },
});

const setErroDocumento = (gerenciadorDocumentos) => ({
  type: SET_DOCUMENTO_ERROR,
  payload: { gerenciadorDocumentos },
});

const getDocumentosComplementaresStart = () => ({
  type: GET_DOCUMENTOS_COMPLEMENTARES_START,
});

const getDocumentosComplementaresSuccess = (listaDocumentosComplementares) => ({
  type: GET_DOCUMENTOS_COMPLEMENTARES_SUCCESS,
  payload: { listaDocumentosComplementares },
});

const getDocumentosComplementaresError = () => ({
  type: GET_DOCUMENTOS_COMPLEMENTARES_ERROR,
});

const insertDocumentosComplementar = (indexPessoa, indexDocumento, documento) => ({
  type: INSERT_DOCUMENTO_COMPLEMENTAR,
  payload: { indexPessoa, indexDocumento, documento },
});

const deleteDocumentosComplementar = (indexPessoa, indexDocumento) => ({
  type: DELETE_DOCUMENTO_COMPLEMENTAR,
  payload: { indexPessoa, indexDocumento },
});

const getDocumentoFormalizarStart = () => ({
  type: GET_DOCUMENTOS_FORMALIZAR_START,
});

const getDocumentoFormalizarSuccess = (listaDocumentosFormalizar) => ({
  type: GET_DOCUMENTOS_FORMALIZAR_SUCCESS,
  payload: { listaDocumentosFormalizar },
});

const getDocumentoFormalizarError = () => ({
  type: GET_DOCUMENTOS_FORMALIZAR_ERROR,
});

const updateDocumentoFormalizarStart = () => ({
  type: UPDATE_DOCUMENTO_FORMALIZAR_START,
});

const updateDocumentoFormalizarSuccess = (listaDocumentosFormalizar) => ({
  type: UPDATE_DOCUMENTO_FORMALIZAR_SUCCESS,
  payload: { listaDocumentosFormalizar },
});

const updateDocumentoFormalizarError = () => ({
  type: UPDATE_DOCUMENTO_FORMALIZAR_ERROR,
});

const insertAnexoDocumentoFormalizar = (indexList, documento) => ({
  type: INSERT_DOCUMENTO_FORMALIZAR,
  payload: {
    indexList, documento,
  },
});

const deleteAnexoDocumentoFormalizar = (indexList, nomeGuid) => ({
  type: DELETE_DOCUMENTO_FORMALIZAR,
  payload: { indexList, nomeGuid },
});

export default {
  types: {
    PREFIX_LIMITES_APROVADOS_UPDATE,
    UPDATE_LIMITE_START,
    UPDATE_LIMITE_ERROR,
    UPDATE_LIMITE_SUCCESS,
    RESET_STORE,
    SET_SELECTED_ITEMS,
    INSERT_TIPO_RELACIONAMENTO_START,
    INSERT_TIPO_RELACIONAMENTO_SUCCESS,
    INSERT_TIPO_RELACIONAMENTO_ERROR,
    INSERT_TIPO_DOCUMENTO_START,
    INSERT_TIPO_DOCUMENTO_SUCCESS,
    INSERT_TIPO_DOCUMENTO_ERROR,
    INSERT_PESSOA_DOCUMENTACAO_START,
    INSERT_PESSOA_DOCUMENTACAO_SUCCESS,
    INSERT_PESSOA_DOCUMENTACAO_ERROR,
    GET_PESSOA_DOCUMENTACAO_START,
    GET_PESSOA_DOCUMENTACAO_SUCCESS,
    GET_PESSOA_DOCUMENTACAO_ERROR,
    DELETE_PESSOA_DOCUMENTACAO_START,
    DELETE_PESSOA_DOCUMENTACAO_SUCCESS,
    DELETE_PESSOA_DOCUMENTACAO_ERROR,
    BUILD_GERENCIADOR,
    DOWNLOAD_DOCUMENTO_START,
    DOWNLOAD_DOCUMENTO_SUCCESS,
    DOWNLOAD_DOCUMENTO_ERROR,
    UPDATE_DOCUMENTO_START,
    UPDATE_DOCUMENTO_SUCCESS,
    UPDATE_DOCUMENTO_ERROR,
    DELETE_DOCUMENTO_START,
    DELETE_DOCUMENTO_SUCCESS,
    DELETE_DOCUMENTO_ERROR,
    SET_DOCUMENTO_ERROR,
    GET_DOCUMENTOS_COMPLEMENTARES_START,
    GET_DOCUMENTOS_COMPLEMENTARES_SUCCESS,
    GET_DOCUMENTOS_COMPLEMENTARES_ERROR,
    INSERT_DOCUMENTO_COMPLEMENTAR,
    DELETE_DOCUMENTO_COMPLEMENTAR,
    GET_DOCUMENTOS_FORMALIZAR_START,
    GET_DOCUMENTOS_FORMALIZAR_SUCCESS,
    GET_DOCUMENTOS_FORMALIZAR_ERROR,
    UPDATE_DOCUMENTO_FORMALIZAR_START,
    UPDATE_DOCUMENTO_FORMALIZAR_SUCCESS,
    UPDATE_DOCUMENTO_FORMALIZAR_ERROR,
    INSERT_DOCUMENTO_FORMALIZAR,
    DELETE_DOCUMENTO_FORMALIZAR,
  },
  insertTipoRelacionamentoStart,
  insertTipoRelacionamentoSuccess,
  insertTipoRelacionamentoError,
  insertTipoDocumentoStart,
  insertTipoDocumentoSuccess,
  insertTipoDocumentoError,
  insertPessoaDocumentacaoStart,
  insertPessoaDocumentacaoSuccess,
  insertPessoaDocumentacaoError,
  setSelectedItems,
  getPessoaDocumentacaoStart,
  getPessoaDocumentacaoSuccess,
  getPessoaDocumentacaoError,
  deletePessoaDocumentacaoStart,
  deletePessoaDocumentacaoSuccess,
  deletePessoaDocumentacaoError,
  updateLimiteStart,
  updateLimiteError,
  updateLimiteSuccess,
  resetStore,
  buildGerenciadorDocumentos,
  downloadDocumentoStart,
  downloadDocumentoSuccess,
  downloadDocumentoError,
  updateDocumentoStart,
  updateDocumentoSuccess,
  updateDocumentoError,
  deleteDocumentoStart,
  deleteDocumentoSuccess,
  deleteDocumentoError,
  setErroDocumento,
  getDocumentosComplementaresStart,
  getDocumentosComplementaresSuccess,
  getDocumentosComplementaresError,
  insertDocumentosComplementar,
  deleteDocumentosComplementar,
  getDocumentoFormalizarStart,
  getDocumentoFormalizarSuccess,
  getDocumentoFormalizarError,
  updateDocumentoFormalizarSuccess,
  updateDocumentoFormalizarError,
  updateDocumentoFormalizarStart,
  insertAnexoDocumentoFormalizar,
  deleteAnexoDocumentoFormalizar,
};

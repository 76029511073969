import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import SummaryPage from 'features/common/summaryPage';
import { Stack, Typography } from '@mui/material';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import CheckIcon from '@mui/icons-material/Check';
import DocumentosFormalizarItem from './documentoFormalizarItem';

const DocumentosFormalizar = ({ listaDocumentosFormalizar, statusDocumentosFormalizar }) => {
  const renderItemStatusDealer = () => {
    const { enviado, envioPendente } = statusDocumentosFormalizar;
    const color = (envioPendente) ? '#BF8900' : '#06C270';
    const status = (envioPendente) ? 'Envio Pendente' : 'Enviado';

    return (
      (enviado || envioPendente)
      && (
        <Stack columnGap="4px" direction="row" color={color}>
          {(enviado && !envioPendente) && (
            <CheckIcon sx={{ fontSize: '16px' }} />
          )}
          {(envioPendente) && (
            <CancelScheduleSendIcon sx={{ fontSize: '17px' }} />
          )}
          <Typography variant="10_medium" color={color} lineHeight="16px" fontSize="12px">{status}</Typography>
        </Stack>
      )
    );
  };
  return (
    <div>
      {!(_.isEmpty(listaDocumentosFormalizar) || _.isNull(listaDocumentosFormalizar))
      && (
        <Stack>
          <SummaryPage
            level={0}
            title="Documentos para formalizar"
            IconTitle={renderItemStatusDealer()}
          >
            {listaDocumentosFormalizar?.map((doc, index) => (
              <DocumentosFormalizarItem
                key={index}
                item={doc}
                indexList={index}
              />
            ))}
          </SummaryPage>
        </Stack>
      )}
    </div>
  );
};

DocumentosFormalizar.propTypes = {
  listaDocumentosFormalizar: PropTypes.array,
  statusDocumentosFormalizar: PropTypes.array,
};

DocumentosFormalizar.defaultProps = {
  listaDocumentosFormalizar: [],
  statusDocumentosFormalizar: [],
};

export default DocumentosFormalizar;

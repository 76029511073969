import styled from 'styled-components';

export const SnackbarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: ${(props) => props.customStyle?.alignItems || 'center'};

  position: fixed;
  bottom: ${(props) => props.customStyle?.bottom || 0};
  left: ${(props) => props.customStyle?.left || 0};
  right: ${(props) => props.customStyle?.right || 'unset'};
  top: ${(props) => props.customStyle?.top || 'unset'};

  width: 100vw;
  z-index: 6;

  pointer-events: none;
`;

import { connect } from 'react-redux';
import documentosComplementares from './documentosComplementares';
import selector from '../redux/selector';

const mapStateToProps = ({ limitesAprovadosCadastro }) => ({
  listaDocumentosComplementares: limitesAprovadosCadastro.listaDocumentosComplementares.listaDados,
  isLoading: limitesAprovadosCadastro.listaDocumentosComplementares.isLoading,
  pendenciaDocumentosFormalizar: selector.pendenciaDocumentosFormalizar(limitesAprovadosCadastro),
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(documentosComplementares);

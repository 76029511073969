import { connect } from 'react-redux';
import DocumentosFormalizar from './documentosFormalizar';
import selector from '../redux/selector';

const mapStateToProps = ({ limitesAprovadosCadastro }) => ({
  listaDocumentosFormalizar: limitesAprovadosCadastro.listaDocumentosFormalizar.listaDados,
  statusDocumentosFormalizar: selector.statusDocumentosFormalizar(
    limitesAprovadosCadastro.listaDocumentosFormalizar,
  ),
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentosFormalizar);

import { createSelector } from 'reselect';

export const filterConcessionarias = (dealerStore) => {
  const { dealers } = dealerStore;
  const groups = [];

  dealers.forEach((dealer) => {
    if (dealer.matrizId !== undefined && dealer.matrizId !== null && dealer.matrizId !== 0) {
      if (dealer.matrizId === dealer.dealerId) {
        const dealerMatriz = dealer.dealerCorr.toString();
        const isGroupPresent = groups.some((group) => (
          group.matrizId === dealerMatriz && group.brand === dealer.brand
        ));

        if (!isGroupPresent) {
          groups.push(dealer);
        }
      }
    }
  });

  return groups;
};

const STATUS_DOC_DESABILITADOS_EDICAO_DEALER = ['docs_formalizar_enviados_sem_retorno'];

export const isEdicaoDocDesabilitadaParaDealer = createSelector(
  (state) => state?.limiteDetails,
  (limiteDetails) => {
    const documento = limiteDetails?.detalhes?.statusLimite;
    return documento != null && STATUS_DOC_DESABILITADOS_EDICAO_DEALER.includes(documento);
  },
);

import React from 'react';
import PropTypes from 'prop-types';

import NewBasicSelect from 'features/common/newBasicSelect/newBasicSelect';

const defaultValue = [{
  value: new Date().getFullYear(),
  label: `${new Date().getFullYear()}`,
}];

const AnoSelector = ({
  ano, setAno, anosList, isLoading,
}) => (
  <NewBasicSelect
    selectFirstOptionDefault
    renderAllOptions={false}
    dataCy="taxaAno"
    nameLabel="Ano"
    options={anosList.length > 0 ? anosList : defaultValue}
    selectedOption={ano}
    setOption={(item) => {
      setAno(item);
    }}
    isLoading={isLoading}
  />
);

AnoSelector.propTypes = {
  ano: PropTypes.number,
  setAno: PropTypes.func,
  anosList: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
};

AnoSelector.defaultProps = {
  ano: new Date().getFullYear(),
  setAno: () => {},
  anosList: null,
  isLoading: false,
};

export default AnoSelector;

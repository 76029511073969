import { React } from 'react';
import PropTypes from 'prop-types';
import { formatCodigoConcessionaria } from 'utils/format';
import moment from 'moment';
import InfoModal from '../infoModal/infoModal';
import formatStatus, { formatValue } from '../../../limitesAprovadosPage/helpers/formatStatus';

const DadosAprovacao = ({ detalhes }) => {
  const formatDate = (date) => {
    const dataFormatada = moment(date);
    return dataFormatada.format('DD-MM-YYYY');
  };

  function obterItensConcessionaria(dados) {
    return [
      { chave: 'Data de aprovação', valor: formatDate(dados?.dataAprovacao) },
      { chave: 'Produto', valor: dados?.descricaoProduto },
      { chave: 'Limite total aprovado', valor: formatValue(dados?.valorLimite?.valor) },
    ];
  }

  function obterItensProduto(dados) {
    return [
      { chave: 'Vencimento', valor: formatDate(dados?.dataVencimento?.valor) },
      { chave: 'Código Buc', valor: formatCodigoConcessionaria(dados?.codDealer) },
      { chave: 'Status', valor: formatStatus(dados?.statusLimite) },
    ];
  }
  return (
    <div className="dados">
      <InfoModal
        descricaoProduto={detalhes?.descricaoProduto}
        itensConcessionaria={obterItensConcessionaria(detalhes)}
        itensProduto={obterItensProduto(detalhes)}
        condicao={detalhes?.condicao?.valor}
        title="Dados da aprovação"
      />
    </div>
  );
};
DadosAprovacao.propTypes = {
  detalhes: PropTypes.any,
};
DadosAprovacao.defaultProps = {
  detalhes: {},
};
export default DadosAprovacao;

import axios from 'axios';

class TaxasService {
  constructor() {
    this.URL_API = window.env.REACT_APP_API_URL;
  }
  async getTaxas(filters) {
    const body = {
      mes: filters.mes,
      ano: filters.ano,
      brand: filters.brand,
    };

    const response = await axios.post(
      `${this.URL_API}/taxas/list`,
      body,
    );

    if (!response || response.status !== 200) {
      throw new Error('Erro ao buscar taxas');
    }

    return response.data;
  }
}

export default new TaxasService();

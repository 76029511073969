import { connect } from 'react-redux';

import MesSelector from './mesSelector';
import operations from '../../../redux/operations';
import selector from '../../../redux/selector';

const mapStateToProps = ({ gestaoPagamentos }) => ({
  mes: gestaoPagamentos?.carteira?.modalTaxas?.filters?.mes,
  mesesList: selector.getListMeses(gestaoPagamentos),
  isLoading: gestaoPagamentos?.carteira?.modalTaxas?.requestStatus?.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setMes: (value) => dispatch(operations.setFilters('mes', value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MesSelector);
